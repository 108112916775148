var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _vm._t("default"),
      _vm.CreateSdkMessageDTO
        ? _c(
            "b-sidebar",
            {
              attrs: {
                id: "add-sdk-message",
                "sidebar-class": "big_sidebar create-sdk-message",
                title: _vm.GetTitle,
                right: "",
                backdrop: "",
                shadow: "",
                "no-slide": "",
                lazy: "",
                "no-close-on-backdrop": "",
                "no-close-on-esc": "",
                "no-header-close": "",
              },
              on: { shown: _vm.shownSidebar, hidden: _vm.closeBtn },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function ({ hide }) {
                      return [
                        _vm.step == 1
                          ? _c(
                              "div",
                              { staticClass: "ml-2" },
                              [
                                _c(
                                  "b-button",
                                  { on: { click: _vm.closeBtn } },
                                  [_vm._v(_vm._s(_vm.$t("CLOSE")))]
                                ),
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "btn-fill",
                                    attrs: {
                                      disabled: _vm.saving || _vm.disabledSave,
                                      variant: "secondary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.saveMessage(true)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("SAVE_DRAFT")))]
                                ),
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "btn-fill",
                                    attrs: { variant: "primary" },
                                    on: {
                                      click: function ($event) {
                                        _vm.step = 3
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("NEXT")))]
                                ),
                                _vm.CreateSdkMessageDTO.message_uuid != ""
                                  ? _c(
                                      "b-button",
                                      {
                                        staticClass: "btn-fill float-right",
                                        attrs: {
                                          disabled: _vm.saving,
                                          variant: "danger",
                                        },
                                        on: { click: _vm.RemoveDraft },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("REMOVE")))]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.step == 3
                          ? _c(
                              "div",
                              { staticClass: "ml-2" },
                              [
                                _c(
                                  "b-button",
                                  { on: { click: _vm.goBackStep1 } },
                                  [_vm._v(_vm._s(_vm.$t("BACK")))]
                                ),
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "btn-fill",
                                    attrs: {
                                      disabled: _vm.saving || _vm.disabledSave,
                                      variant: "secondary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.saveMessage(true)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("SAVE_DRAFT")))]
                                ),
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "btn-fill",
                                    attrs: {
                                      disabled: _vm.disabledPresendMessage,
                                      variant: "primary",
                                    },
                                    on: { click: _vm.preSendMessage },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("SUBMIT")))]
                                ),
                                _vm.CreateSdkMessageDTO.message_uuid != ""
                                  ? _c(
                                      "b-button",
                                      {
                                        staticClass: "btn-fill float-right",
                                        attrs: {
                                          disabled: _vm.saving,
                                          variant: "danger",
                                        },
                                        on: { click: _vm.RemoveDraft },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("REMOVE")))]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.step == 2
                          ? _c(
                              "div",
                              { staticClass: "ml-2" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        _vm.step = 3
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("CLOSE")))]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.step == 4 || _vm.step == 5
                          ? _c(
                              "div",
                              { staticClass: "ml-2" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        _vm.step = 1
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("CLOSE")))]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ],
                null,
                false,
                3529286736
              ),
              model: {
                value: _vm.show,
                callback: function ($$v) {
                  _vm.show = $$v
                },
                expression: "show",
              },
            },
            [
              _c(
                "b-overlay",
                {
                  attrs: {
                    show: !_vm.CreateSdkMessageDTO.isDraft && _vm.saving,
                    rounded: "sm",
                  },
                },
                [
                  _vm.settings && _vm.loaded
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "view-content compose-content create-message px-3 py-2 pb-5",
                        },
                        [
                          _vm.step == 1 || _vm.step == 3
                            ? _c(
                                "div",
                                [
                                  _vm.settings.environment == "QA"
                                    ? _c(
                                        "b-alert",
                                        {
                                          staticClass: "pt-2 pb-2",
                                          attrs: {
                                            show: "",
                                            variant: "warning",
                                          },
                                        },
                                        [
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$t(
                                                  "SDK.ENVIRONMENT.QA_NOTIFICATION"
                                                )
                                              ),
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.settings.environment == "OPEN_TEST"
                                    ? _c(
                                        "b-alert",
                                        {
                                          staticClass: "pt-2 pb-2",
                                          attrs: {
                                            show: "",
                                            variant: "warning",
                                          },
                                        },
                                        [
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$t(
                                                  "SDK.ENVIRONMENT.OPEN_TEST_NOTIFICATION"
                                                )
                                              ),
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.step == 1
                            ? _c(
                                "div",
                                [
                                  _vm.CreateSdkMessageDTO
                                    .response_to_message_uuid == ""
                                    ? _c("SelectFrom", {
                                        attrs: {
                                          "sdk-message": "",
                                          functionbox_uuid:
                                            _vm.functionbox_uuid,
                                          user_uuid: _vm.user_uuid,
                                        },
                                        model: {
                                          value: _vm.selected_from,
                                          callback: function ($$v) {
                                            _vm.selected_from = $$v
                                          },
                                          expression: "selected_from",
                                        },
                                      })
                                    : _vm._e(),
                                  _c("div", { staticClass: "mt-2" }, [
                                    _vm._v(_vm._s(_vm.$t("TO"))),
                                  ]),
                                  !_vm.to_sdk
                                    ? _c("div", { staticClass: "mb-2" }, [
                                        _vm._v(_vm._s(_vm.$t("NONE_SELECTED"))),
                                      ])
                                    : _vm._e(),
                                  _vm.to_sdk
                                    ? _c("div", { staticClass: "mb-2" }, [
                                        _c(
                                          "div",
                                          { staticClass: "sdk-recipient" },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.to_sdk.attributes.name
                                                ) +
                                                ",\n              " +
                                                _vm._s(
                                                  _vm.to_sdk.relationships
                                                    .parent.data.name
                                                ) +
                                                "\n              "
                                            ),
                                            _vm.to_sdk.attributes.name !=
                                            _vm.to_sdk.attributes.description
                                              ? _c("div", [
                                                  _c("small", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.to_sdk.attributes
                                                          .description
                                                      )
                                                    ),
                                                  ]),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "mt-1",
                                          attrs: {
                                            size: "sm",
                                            variant: "primary",
                                          },
                                          on: { click: _vm.addSdkRecipient },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fal fa-bookmark",
                                          }),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.$t("SDK.SELECT_RECIPIENT")
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "mt-1",
                                          attrs: {
                                            size: "sm",
                                            variant: "primary",
                                          },
                                          on: {
                                            click: _vm.addNewSdkParticipant,
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fal fa-search",
                                          }),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.$t(
                                                  "SDK.SELECT_FROM_ADRESSBOOK"
                                                )
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "mt-1",
                                          attrs: {
                                            size: "sm",
                                            variant: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.addWithIdentifier = true
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fal fa-plus",
                                          }),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.$t("SDK.ADD_WITH_IDENTIIER")
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      ),
                                      _vm.to_sdk
                                        ? _c(
                                            "b-button",
                                            {
                                              staticClass: "mt-1",
                                              attrs: {
                                                size: "sm",
                                                variant: "secondary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.to_sdk = null
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fal fa-trash-alt",
                                              }),
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(_vm.$t("CLEAR")) +
                                                  "\n            "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-modal",
                                    {
                                      attrs: {
                                        id: "add-sdk-with-identiier",
                                        "hide-header": "",
                                        "ok-only": "",
                                        centered: "",
                                        "dialog-class": _vm.teams.theme,
                                        "ok-title": _vm.$t("CANCEL"),
                                      },
                                      on: { ok: _vm.closeAddIdentifier },
                                      model: {
                                        value: _vm.addWithIdentifier,
                                        callback: function ($$v) {
                                          _vm.addWithIdentifier = $$v
                                        },
                                        expression: "addWithIdentifier",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          trim: "",
                                          placeholder: _vm.$t("SDK.IDENTIFIER"),
                                        },
                                        model: {
                                          value: _vm.search_identifier,
                                          callback: function ($$v) {
                                            _vm.search_identifier = $$v
                                          },
                                          expression: "search_identifier",
                                        },
                                      }),
                                      _c("b-form-input", {
                                        staticClass: "mt-2",
                                        attrs: {
                                          trim: "",
                                          placeholder:
                                            _vm.$t("ORGANISATION.TITLE"),
                                        },
                                        model: {
                                          value: _vm.search_organisation,
                                          callback: function ($$v) {
                                            _vm.search_organisation = $$v
                                          },
                                          expression: "search_organisation",
                                        },
                                      }),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "mt-2",
                                          attrs: {
                                            disabled:
                                              _vm.search_identifier == "",
                                            variant: "primary",
                                          },
                                          on: { click: _vm.searchIdentifier },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("FETCH_INFORMATION"))
                                          ),
                                        ]
                                      ),
                                      _vm.searchItems.length != 0
                                        ? _c("b-table", {
                                            staticClass: "mt-2",
                                            attrs: {
                                              striped: "",
                                              hover: "",
                                              items: _vm.searchItems,
                                              fields: _vm.searchFields,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "cell(Type)",
                                                  fn: function (data) {
                                                    return [
                                                      _vm._v(
                                                        "\n                " +
                                                          _vm._s(
                                                            data.item.attributes
                                                              .name
                                                          ) +
                                                          ",\n                " +
                                                          _vm._s(
                                                            data.item
                                                              .relationships
                                                              .parent.data.name
                                                          ) +
                                                          "\n                "
                                                      ),
                                                      _c("br"),
                                                      _c(
                                                        "b-button",
                                                        {
                                                          attrs: {
                                                            variant: "dark",
                                                            size: "sm",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.useAddress(
                                                                data.item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t("USE")
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(id)",
                                                  fn: function (data) {
                                                    return [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          attrs: {
                                                            variant: "dark",
                                                            size: "sm",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.useAddress(
                                                                data.item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t("USE")
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2537736947
                                            ),
                                          })
                                        : _vm._e(),
                                      _vm.searchDone &&
                                      _vm.searchItems.length == 0
                                        ? _c("div", [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.$t("SDK.NOT_FOUND_SEARCH")
                                                ) +
                                                "\n            "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "mt-2" }, [
                                    _vm._v(_vm._s(_vm.$t("INFORMATION"))),
                                  ]),
                                  _c("InputSubject", {
                                    attrs: {
                                      settings: _vm.settings,
                                      limit: 256,
                                    },
                                    on: {
                                      valid: (n) => (_vm.validatedSubject = n),
                                    },
                                    model: {
                                      value: _vm.CreateSdkMessageDTO.subject,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.CreateSdkMessageDTO,
                                          "subject",
                                          $$v
                                        )
                                      },
                                      expression: "CreateSdkMessageDTO.subject",
                                    },
                                  }),
                                  _c("InputBody", {
                                    attrs: {
                                      settings: _vm.settings,
                                      limit: 20000,
                                    },
                                    on: {
                                      valid: (n) => (_vm.validatedBody = n),
                                    },
                                    model: {
                                      value: _vm.CreateSdkMessageDTO.body,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.CreateSdkMessageDTO,
                                          "body",
                                          $$v
                                        )
                                      },
                                      expression: "CreateSdkMessageDTO.body",
                                    },
                                  }),
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      staticClass: "mt-2",
                                      attrs: {
                                        id: "checkbox-isconfidential",
                                        name: "checkbox-isconfidential",
                                        value: true,
                                        "unchecked-value": false,
                                      },
                                      model: {
                                        value:
                                          _vm.CreateSdkMessageDTO
                                            .isconfidential,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.CreateSdkMessageDTO,
                                            "isconfidential",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "CreateSdkMessageDTO.isconfidential",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(_vm.$t("SDK.IsConfidential")) +
                                          "\n          "
                                      ),
                                    ]
                                  ),
                                  _vm.CreateSdkMessageDTO.isconfidential
                                    ? _c(
                                        "div",
                                        [
                                          _c("b-form-radio-group", {
                                            attrs: {
                                              options: _vm.confidentialOptions,
                                              stacked: "",
                                            },
                                            model: {
                                              value:
                                                _vm.CreateSdkMessageDTO
                                                  .confidentialLevel,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.CreateSdkMessageDTO,
                                                  "confidentialLevel",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "CreateSdkMessageDTO.confidentialLevel",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.step == 3,
                                  expression: "step == 3",
                                },
                              ],
                            },
                            [
                              _c("div", { staticClass: "mt-2" }, [
                                _vm._v(_vm._s(_vm.$t("ADD_FILES"))),
                              ]),
                              _c(
                                "div",
                                {
                                  key:
                                    "AddFiles" +
                                    _vm.CreateSdkMessageDTO.message_uuid,
                                  attrs: {
                                    show:
                                      _vm.CreateSdkMessageDTO.message_uuid ==
                                      "",
                                    rounded: "sm",
                                  },
                                },
                                [
                                  _c("InputFiles", {
                                    attrs: {
                                      message_uuid:
                                        _vm.CreateSdkMessageDTO.message_uuid,
                                      functionbox_uuid:
                                        _vm.CreateSdkMessageDTO
                                          .functionbox_uuid,
                                      user_uuid:
                                        _vm.CreateSdkMessageDTO.user_uuid,
                                      "max-size": 30,
                                      "total-size": "",
                                      "cannot-upload":
                                        _vm.$t("DRAFT.SDK_UPLOAD"),
                                    },
                                    on: {
                                      valid: (n) => (_vm.validatedFiles = n),
                                    },
                                    model: {
                                      value:
                                        _vm.CreateSdkMessageDTO.attachments,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.CreateSdkMessageDTO,
                                          "attachments",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "CreateSdkMessageDTO.attachments",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "mt-2" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("SDK.EXTRA_INFORMATION")) +
                                    "\n          "
                                ),
                              ]),
                              _c(
                                "b-button",
                                {
                                  staticClass: "no-border",
                                  attrs: { size: "sm", variant: "secondary" },
                                  on: {
                                    click: function ($event) {
                                      _vm.step = 2
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "fal fa-plus" }),
                                  _vm._v(" \n            "),
                                  _vm.metadataItemsCount == 0
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("SDK.ADD_EXTRA_INFO_EMPTY")
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.metadataItemsCount != 0
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("SDK.ADD_EXTRA_INFO", {
                                              0: _vm.metadataItemsCount,
                                            })
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm.step == 2
                            ? _c("SDKReferences", {
                                key: _vm.referencesKey,
                                attrs: {
                                  fromParticipantPersons:
                                    _vm.CreateSdkMessageDTO
                                      .fromParticipantPersons,
                                  fromParticipantReferences:
                                    _vm.CreateSdkMessageDTO
                                      .fromParticipantReferences,
                                  toParticipantPersons:
                                    _vm.CreateSdkMessageDTO
                                      .toParticipantPersons,
                                  toParticipantReferences:
                                    _vm.CreateSdkMessageDTO
                                      .toParticipantReferences,
                                },
                                on: {
                                  cancel: function ($event) {
                                    _vm.step = 3
                                  },
                                  ok: _vm.setReferences,
                                },
                              })
                            : _vm._e(),
                          _vm.step == 5
                            ? _c("SDKSearch", {
                                attrs: {
                                  functionbox_uuid:
                                    _vm.CreateSdkMessageDTO.functionbox_uuid,
                                },
                                on: {
                                  cancel: function ($event) {
                                    _vm.step = 1
                                  },
                                  selectedAddress: _vm.selectedAddress,
                                },
                              })
                            : _vm._e(),
                          _vm.step == 4
                            ? _c("SDKRecipients", {
                                attrs: {
                                  functionbox_uuid:
                                    _vm.CreateSdkMessageDTO.functionbox_uuid,
                                },
                                on: {
                                  cancel: function ($event) {
                                    _vm.step = 1
                                  },
                                  selectedAddress: _vm.selectedAddress,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }